<template>
  <div>
    <van-nav-bar title="返回" left-arrow @click-left="gohome()" />
    <div class="pageError">
      <img src="../assets/image/403.png" alt="" class="img" />
    </div>
  </div>
</template>
<script>
export default {
  name: "page403",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gohome() {
      this.$router.push({
        path: "/myhome",
      });
    },
  },
};
</script>
<style scoped lang="less">
.img {
  width: 300px;
  object-fit: fill;
}
</style>
